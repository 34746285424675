
<div   fxHide fxShow.gt-sm *ngIf="breadcrumbType == '0'">
  &nbsp;<div id="breadcrump" fxLayout="row wrap" 
  *ngIf="router.url != '/' && router.url != '/home'">
    <div fxFlex="100">
        <mat-card  style="border-radius: 0px;" fxLayout="row" class="breadcrumb light-block">
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutGap="5px"
             fxLayoutAlign="start center">
                <mat-icon>home</mat-icon>
                <span class="breadcrumb-title">HOMEPAGE</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item upperCase" fxLayout="row" fxLayoutAlign="start center">
                <a class="cursor-pointer" [hidden]="i == (breadcrumbs.length - 1)"  (click)="navigate(breadcrumb)"  >{{breadcrumb.name}}</a>
  
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active"><b class="text">{{breadcrumb.name}}</b></span>
            </div>
        </mat-card>
  
        <div *ngIf="displayDescripion" fxLayout="row">
            <small style="padding-bottom:10px;padding-left:5px;" >{{categoryDetails?.description}}</small>
         </div>
    </div>
  
  </div>
</div>


<div fxHide fxShow.gt-sm *ngIf="breadcrumbType == '1'">
  &nbsp;<div id="breadcrump" fxLayout="row wrap" 
  *ngIf="router.url != '/' && router.url != '/home'">
    <div fxFlex="100">
        <mat-card  style="border-radius: 0px;" fxLayout="row" class="breadcrumb light-block2">
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item2" fxLayout="row" fxLayoutGap="5px"
             fxLayoutAlign="start center">
                <p class="breadcrumb-title2">HOMEPAGE</p>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item2 upperCase" fxLayout="row" fxLayoutAlign="start center">
                <a class="cursor-pointer" [hidden]="i == (breadcrumbs.length - 1)"  (click)="navigate(breadcrumb)"  >                  
                  <p class="upperCase2">{{breadcrumb.name}}</p></a>
  
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active" ><b class="text upperCase2">{{breadcrumb.name}}</b></span>
            </div>
        </mat-card>
  
        <div *ngIf="displayDescripion" fxLayout="row">
            <small style="padding-bottom:10px;padding-left:5px;" >{{categoryDetails?.description}}</small>
      
         </div>
    </div>  
  </div>
</div>

<div fxHide fxShow.gt-sm *ngIf="breadcrumbType == '2'">
  &nbsp;<div id="breadcrump" fxLayout="row wrap" 
  *ngIf="router.url != '/' && router.url != '/home'">
    <div fxFlex="100">
        <mat-card  style="border-radius: 0px;" fxLayout="row" class="breadcrumb light-block3">
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item3" fxLayout="row" fxLayoutGap="5px"
             fxLayoutAlign="start center">
                <p class="breadcrumb-title2">HOMEPAGE</p>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item3 upperCase" fxLayout="row" fxLayoutAlign="start center">
                <a class="cursor-pointer " [hidden]="i == (breadcrumbs.length - 1)"  (click)="navigate(breadcrumb)" >
                  <p class="upperCase2">{{breadcrumb.name}}</p></a>
  
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active"><b class="text upperCase2">{{breadcrumb.name}}</b></span>
            </div>
        </mat-card>
  
        <div *ngIf="displayDescripion" fxLayout="row">
            <small style="padding-bottom:10px;padding-left:5px;" >{{categoryDetails?.description}}</small>      
         </div>
    </div>  
  </div>
</div>

<div fxLayoutAlign="start stretch"  ngClass.gt-sm="padding-b10"
*ngIf="displayCategoryImage" fxLayout="column">
<img [src]="path" onerror="this.src=''"  loading="lazy">
</div>




